.AdminAppContainer {
  width: 100%;
  
}

.formDivAligner {
  
}

.formDiv {
    /* padding: 10px; */
    padding-left: 20px;
    padding-right: 30px;
    /* align-items: center; */
    margin-top: 30px;
    max-width: 500px;
  width: 80%;
  min-height: 400px;
  height: min-content;
  border-radius: 15px;
  /* padding: 20px; */
  box-shadow: 1px 1px 5px 2px #00000036;
  /* display: flex; */
  transition: 1s;
}

.sellectOptionNbutton {
  flex-direction: column;
  padding-bottom: 10px;
  margin-left: 12px;
}

.formHeader {
    font-size: 25px;
    font-weight: bold;
    color: white;
}

label {
    font-size: 15px;
    /* font-weight: bold; */
    display: block;
    margin: 10px 0px 5px 8px;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    /* margin-bottom: .5rem; */
  }

  /* input {
    padding: 8px;
    margin-left: 15px;
    font-size: 16px;
    width: 60%;
    display: block;
    border-radius: 4px;
    border: 1px solid #ccc;
  } */

.selector {
    margin-top: 20px;
    /* margin-bottom: 20px; */
    padding: 10px;
    margin-left: 15px;
    /* background-color: wheat; */
}


.submitButton1 {
    background: #252d4a;
    width: 200px;
    margin-top: 20px;
    padding: 10px;
    color: white;
    border-radius: 10px;
    cursor: pointer;
    font-size: 15px;
    letter-spacing: 0.2em;
    text-align: center;
    transition: 0.5s;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    }
  
    .submitButton1:hover {
      background-color: #131727;
    }
  
  input:focus {
    /* border-color: #007eff;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 0 3px rgba(0, 126, 255, 0.1);
    outline: none; */
  }
  
  input.error {
    /* border-color: red; */
  }
  
  
  
  .input-feedback {
    color: red;
    margin-top: .25rem;
    margin-left: 10px;
    font-size: 12px;
  }


.questionsMainDiv {
    display: flex;
    /* justify-content: center; */
    align-items: center;
}
.questionsView {
    /* padding: 5px; */
    display: flex;
    /* justify-content: ; */
    justify-content: center;
    /* align-items: flex-start; */
    align-items: center;
    
    margin-left: 20px;
    margin-right: 20px;
    /* background-color: wheat; */
    margin-bottom: 5px;
    margin-top: 5px;
    width: 100%;
}

.questonsSubMainDiv{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.insideQuestionsView {
    background-color: #fff;
    width: 350px;
    flex-wrap: wrap;
    min-height: 200px;
    height: min-content;
    border-radius: 15px;
    padding: 20px;
    box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: space-evenly;
    margin: 10px;
    transition: 1s;
    display: flex;
    flex-direction: column;
}