.appContainer {
  background: linear-gradient(45deg, #5ca54b, #000000);
  width: 100%;
    min-height: 200px;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 1s;
}



  /* in quizPlay */

  .scoreContainer {
    width: 80%;
    height: 200px;
    display: flex;
    flex-direction: column;
    color: white;
    justify-content: space-between;
  }

  .scoreContainer p {
    margin: 0px
  }

  .scoreNumber {
    display: flex;
  }

  .retryButton {
    width: 90%;
  }

  

  /* questionCard */

  .timeOutContainer {
    color: white;
    width: 80%;
  }

  
/* START */
.questionDiv {
  background-color: #5ca54b;
  max-width: 500px;
  width: 80%;
  min-height: 400px;
  height: min-content;
  border-radius: 15px;
  /* padding: 20px; */
  box-shadow: 1px 1px 5px 2px #00000036;
  display: flex;
  justify-content: space-evenly;
  margin: 10px;
  transition: 1s;
}

  .question-count-timer {
    display: flex;
    /* background-color: grey; */
    /* margin-bottom: 20px; */
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px;

  }

  .question-count{
    color: white;
    font-size: 24px;
  }

  .timer {
    color: white;
    font-size: 20px;
    font-weight: bold;
    margin-right: 10px;
    transition: 1s;
  }
  
  .questionHeadText {
    font-size: 20px;
    padding-left: 20px;
    margin-bottom: 20px;
    font-weight: bold;
    color: white;
  }

  .question-and-answer-section {
    width: 80%;
    margin-left: 10%;
  }
  
  .question-text {
    margin-bottom: 12px;
    font-size: 20px;
    color: white;
  }
  
  
  .answer-section {
    width: 80%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }

  #answerButton {
    width: 100%;
    margin: 3px 0px 3px 0px;
  }
  
/* //DONE */

/* ivide vare correctaaa */

  /* button {
    width: 100%;
    font-size: 16px;
    color: #ffffff;
    background-color: #252d4a;
    border-radius: 15px;
    display: flex;
    padding: 15px;
    padding-left: 20px;
    justify-content: flex-start;
    align-items: center;
    border: 2px solid #234668;
    cursor: pointer;
    margin: 3px;
    font-size: 16px;
    letter-spacing: 0.06em;
    transition: 0.5s;
  }

  .button-selected{
      background-color: black;
  }
  .button-selected:hover{
      background-color: #1e2027;      
  }

  button:hover {
    background-color: #555e7d;
  }
  
  button:focus {
    outline: none;
  }

  .submitButtonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    
  }

.submitButton {
  background: #252d4a;
  width: 200px;
  margin-top: 20px;
  padding: 10px;
  color: white;
  border-radius: 15px;
  cursor: pointer;
  font-size: 15px;
  letter-spacing: 0.2em;
  text-align: center;
  transition: 0.5s;
  text-transform: uppercase;
  }

  .submitButton:hover {
    background-color: #131727;
  }

  .next-and-prev-buttonsContainer {
    display: flex;
    margin-top: 20px;
  }

  .button1 {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #225218;
    width: 100px;
    color: white;
    border-radius: 15px;
    cursor: pointer;
    font-size: 15px;
    letter-spacing: 0.2em;
    text-align: center;
    transition: 0.5s;
    padding: 10px;
    text-transform: uppercase;
    font-size: 12px;
}
 */

/* ividunnu angoooottum correctaaa */
/* quiz SCREEN */
/* DONE */
.quizScreenContainer {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}

.linksContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.singleLinkContainer {
  width: 80%;
  max-width: 600px;
  border-radius: 15px;
  transition: 1s;
  box-shadow: 1px 1px 5px 2px #00000036;
  margin: 10px;
}

.singleLinkContainer:hover{
  background-color: #437937;
  color: #ffffff;
}

.link1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  /* padding: 10px; */
  color: white;
  background-color: #5ca54b;


}

.link1 div {
  /* right: 10px; */
  padding-left: 25px;
}

.link1 p {
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 0;
}

.link1 img{
  height: 35px;
  width: 35px;
  padding-right: 20px;
}

.link2 {
  display: flex;
  height: 60px;
  align-items: center;
}

.link2 p {
  margin: 0;
  padding: 0;
  font-size: 18px;
  letter-spacing: 0.05em;
}

/* //IMP */

img {
  height: 50px;
  width: 50px;
  margin-bottom: 10px;
}