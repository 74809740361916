.rankTable {
    /* height: 200px;
    width: 100%; */
    margin-top: 5px;
    /* display: flex; */
    flex-direction: column;
    flex: 5;
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}

.singleRankRow {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    color: white;
}



.rankTextStyle {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
}
.rankText {
    flex: 2;
}

.nameText {
    /* flex: 3; */
    flex: 1;
    display: flex;
    justify-content: flex-start;
    padding-left: 12%;
}

.scoreText {
    /* flex: 3; */
    font-weight: bold;
    flex: 1;
}

.rankHeadRowText {
    color: white;
    font-size: 20px;
    letter-spacing: 0.2em;
}

/* //jdahfkjshjdsfhdskjdsf */
.rankCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 1px 1px 5px 2px #00000036;
    border-radius: 15px;
    overflow: hidden;
    transition: 1s;
    width: 70%;
    max-width: 600px;
    margin: 10px 0px;
}

.rankCardHeight400 {
    height: 270px;
}

.rankCardHeight60 {
    height: 60px;
}



.rankHeaderDiv {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    font-size: 18px;
    letter-spacing: 0.05em;
    transition: 1s;
    padding-top: 5px;
    justify-content: flex-start;
    padding-left: 25%;
    color: white;
}

.rankHeaderDivClick{
    cursor: pointer;
    /* justify-content: flex-start; */
    padding-left: 25px;

}

.rankHeaderDivClick:hover {
    background-color: #3e423d;
}


.hide-section1 {
    display: none;
}

