.footerDiv {
    height: 100px;
    width: 100%;
    display: flex;
    margin-bottom: 20px;
}

.footerContain1 {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 20px;
    border-right: 2px solid #5ca54b;
    opacity: 0.6;

}

.footerLogo {
    width: 65.5px;
    height: 50px;
}

.footerContain2 {
    flex: 1;
    /* width: 60%; */
    display: flex;
    flex-direction: column;

}

.footerContain2 p{
    display: flex;
    align-items: center;
    margin: 0;
    flex: 1;
    font-size: 13px;
    padding-left: 30px;

}

@media only screen and (max-width: 600px) {
    #fontResponsive {
        font-size: 10px;
        padding-left: 20px;
    }
  }