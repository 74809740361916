.loader {
    /* border-width: 5px; */
    border-style: solid;
    border-top-color: #5ca54b;
    border-bottom-color: #f3f3f3;
    border-right-color: #f3f3f3;
    border-left-color: #f3f3f3;
    border-radius: 50%;
    /* border: 5px solid #f3f3f3;
    border-radius: 50%;
    border-top: 5px solid #3498db; */
    /* width: 120px;
    height: 120px; */
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }