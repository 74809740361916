.questionDiv1 {
    background-color: #5ca54b;
    max-width: 500px;
    width: 80%;
    min-height: 300px;
    height: min-content;
    border-radius: 15px;
    /* padding: 20px; */
    box-shadow: 1px 1px 5px 2px #00000036;
    display: flex;
    justify-content: space-evenly;
    margin: 10px;
    transition: 1s;
    padding-top: 25px;
  }

  .questionHeadText1 {
    font-size: 20px;
    padding-left: 20px;
    margin-bottom: 20px;
    font-weight: bold;
    color: white;
    display: flex;
    width: 80%;
    justify-content: space-between;
  }

  .questionHeadText1 img {
    width: 20px;
    height: 20px;
  }

  .question-and-answer-section1 {
    width: 80%;
    margin-left: 10%;
  }
  
  .question-text1 {
    margin-bottom: 12px;
    font-size: 20px;
    color: white;
  }

  .answer-section1 {
    width: 80%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    /* background-color: blue; */
  }

  #answerButton1 {
    width: 100%;
    margin: 3px 0px 3px 0px;
    /* background-color: black; */
    transition: 1s;
}

#loadingButton1 {
    width: 100%;
    margin: 3px 0px 3px 0px;
    /* background-color: black; */
    transition: 1s;
    /* padding: 0; */
    /* justify-self: center; */
    /* align-self: center; */
}

#answerButton2 {
    width: 100%;
    margin: 3px 0px 3px 0px;
    background-color: black;
    transition: 1s;
}

.showAnswerButton  {
    /* background-color: black; */
    color: black;
    transition: 1s;
}

.showAnswerButton:hover  {
    background-color: grey;
}