.navContainer{
    display: flex;
    height: 80px;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    background-color: #5ca54b;
}

.navContainer img {
    margin-left: 15px;
}

.navLinks{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    /* overflow: hidden; */
    text-transform: uppercase;
    font-size: 20px;
    color: black;
    font-style: normal;
    text-decoration: none;
    /* margin-right: 10px; */
    padding: 0px 10px 0px 10px;
    /* margin-right: 10px; */
    border-radius: 1px;
    border: black;
    border-style: none;
    border-color: black;
    transition: 1s;
}

.navLinks:hover {
    background-color: #225218;
    color: white;
}

.energyQuizLink {
    text-decoration: none;
    color: white;
    font-size: 22px;
    padding-right: 10px;
}

.goToProfileLink {
    background-color: white;
    text-decoration: none;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    /* border-width: 2px; */
    margin-right: 10px;
    color: black;
}